import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseDataReducerV2InitialState, basedDataReducerV2 } from 'redux/base-reducer-v2';
import { ICompanyType } from '../types/company-type.types';

export interface CompanyTypeInitialState extends BaseDataReducerV2InitialState<ICompanyType> {}

const { initialState, AC, reducer, thunks } = basedDataReducerV2<ICompanyType, CompanyTypeInitialState>(
  'company-type-base',
);

const companyTypeInitialState: CompanyTypeInitialState = {
  ...initialState,
  perPage: 15,
};

export const companyType = createSlice({
  name: 'companyType',
  initialState: companyTypeInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as CompanyTypeInitialState, action);
    });
  },
});
export const CompanyTypeAC = AC;

export const setCompanyTypeCurrentPage = AC.setCurrentPage;
export const onCompanyTypeSort = thunks.onSort;
export const onCompanyTypeFilter = thunks.onFilter;
export const clearCompanyTypeFilters = AC.clearFilters;

export const {} = companyType.actions;
