import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from 'modules/apiBaseQuery';
import { ApiPaginationResponse, ApiResponse } from 'types';
import {
  FetchProductionDevicesPayload,
  GetProductionDevicePayload,
  ProductionDevice,
  ProductionDeviceSearchResponse,
  SearchProductionDevicePayload,
  UpdateProductionDevicePayload,
} from '../types/necs-production-device.types';

export const necsProductionDeviceApi = createApi({
  reducerPath: 'necsProductionDeviceApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}/v2/api/admin/necs/production-devices`),
  endpoints: (builder) => ({
    fetchNecsProductionDevices: builder.query<ApiPaginationResponse<ProductionDevice[]>, FetchProductionDevicesPayload>(
      {
        query: (params) => ({
          url: ``,
          method: 'GET',
          params,
        }),
      },
    ),
    getProductionDevice: builder.query<ApiResponse<ProductionDevice>, GetProductionDevicePayload>({
      query: (params) => ({
        url: `/${params.id}`,
        method: 'GET',
      }),
    }),

    updateProductionDevice: builder.mutation<ApiResponse<ProductionDevice>, UpdateProductionDevicePayload>({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),

    searchProductionDevices: builder.query<
      ApiResponse<ProductionDeviceSearchResponse[]>,
      SearchProductionDevicePayload
    >({
      query: (params) => ({
        url: `/search`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetProductionDeviceQuery,
  useUpdateProductionDeviceMutation,
  useLazyFetchNecsProductionDevicesQuery,
  useLazySearchProductionDevicesQuery,
} = necsProductionDeviceApi;
