import API from 'api/api';
import { PUBLIC_PAGES } from 'general/constants';

const checkAuth = async (pathname: string) => {
  try {
    await API.user.getMe();
    if (PUBLIC_PAGES.includes(pathname)) {
      window.location.href = '/dashboard';
    }
  } catch (error) {
    if (!PUBLIC_PAGES.includes(pathname)) {
      window.location.href = '/login';
    }
  }
};

export const initCheckAuthLoop = (pathname: string) => {
  document.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'visible') {
      checkAuth(pathname);
    }
  });
  setTimeout(() => checkAuth(pathname), 1000 * 60);
};
