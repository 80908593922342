import type { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const getApiErrorMessage = (error: FetchBaseQueryError | SerializedError | undefined | any) => {
  if (error && 'message' in error) {
    return error.message;
  }
  if (error && 'data' in error && error.data?.message) {
    return error.data.message;
  }
  return null;
};

export const generateFormData = (data: Record<string, any>): FormData => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (key.includes('[]')) {
      for (const [i, val] of data[key].entries()) {
        formData.append(key, val);
      }
    } else {
      formData.append(key, value || '');
    }
  });
  return formData;
};
