import API from '@api';
import { toast } from 'react-toastify';
import _, { cloneDeep } from 'lodash';
import { setNecsAccount } from 'modules/necs';
import { getUserFullName } from '@helpers';
import { onError } from './error-reducer';

const TOGGLE_INIT = '/nibchain/session/TOGGLE_INIT';
const TOGGLE_LOADING = '/nibchain/session/TOGGLE_LOADING';
const SET_USER = '/nibchain/session/SET_USER';
const SET_CHAT_USER = '/nibchain/session/SET_CHAT_USER';
const SET_UNREAD_CHANNELS = '/nibchain/session/SET_UNREAD_CHANNELS';

const initialState = {
  isInited: false,
  loading: false,
  user: null,
  chatUser: null,
  unread_channels: [],
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_INIT:
      return {
        ...state,
        isInited: action.payload,
      };
    case SET_USER:
      let user = action.payload,
        roles = [];
      if (user?.roles) {
        roles = user.roles;
        user.hasAdminAccess = roles.map((role) => role.slug.toLowerCase()).includes('admin');

        user.hasCompanyAdminAccess = roles.map((role) => role.slug.toLowerCase()).includes('company_admin');

        user.hasMarketplaceAdminAccess = roles.map((role) => role.slug.toLowerCase()).includes('marketplace_admin');
        user.isMarketplaceManager = roles.map((role) => role.slug.toLowerCase()).includes('marketplace_manager');

        let marketplaceAccess = false;
        for (let role of user.roles)
          if (role.slug.toLowerCase().indexOf('marketplace') > -1 || role.slug.toLowerCase() === 'admin') {
            marketplaceAccess = true;
            break;
          }
        user.hasMarketplaceAccess = marketplaceAccess;
      } else {
        if (user) {
          user.roles = roles;
        }
      }
      if (user) user.fullName = getUserFullName(user);
      return {
        ...state,
        user,
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_UNREAD_CHANNELS:
      return {
        ...state,
        unread_channels: action.payload,
      };
    case SET_CHAT_USER:
      return {
        ...state,
        chatUser: action.payload,
      };
    default:
      return { ...state };
  }
};

const toggleInitAC = (status) => ({ type: TOGGLE_INIT, payload: status });
const toggleLoadingAC = (status) => ({ type: TOGGLE_LOADING, payload: status });
const setUserAC = (user) => ({ type: SET_USER, payload: user });

export const initSession = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(toggleLoadingAC(true));
      let user = await API.user.getMe();
      dispatch(setUserAC(user));
      if (user?.company?.necs_account) {
        dispatch(setNecsAccount(cloneDeep(user.company.necs_account)));
      } else {
        dispatch(setNecsAccount(null));
      }
      dispatch(toggleLoadingAC(false));
      resolve(user);
    } catch (error) {
      dispatch(toggleLoadingAC(false));
      resolve(null);
    }
  });
};

export const getSession = (login, password) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(toggleLoadingAC(true));
      await API.user.getCsrf();
      await API.user.auth({ login, password });
      let user = await API.user.getMe();
      dispatch(setUserAC(user));
      if (user?.company?.necs_account) {
        dispatch(setNecsAccount(cloneDeep(user.company.necs_account)));
      } else {
        dispatch(setNecsAccount(null));
      }
      dispatch(toggleLoadingAC(false));
      dispatch(toggleInitAC(true));
      toast.dark(`You are success logged in as ${getState().session.user.fullName}`);
      resolve(user);
    } catch (error) {
      dispatch(toggleLoadingAC(false));
      reject(error);
    }
  });
};

export const logout =
  (withLogoutQuery = true) =>
  async (dispatch) => {
    dispatch(toggleLoadingAC(true));
    dispatch(setUserAC(null));
    dispatch(toggleInitAC(false));
    dispatch(toggleLoadingAC(false));
    if (withLogoutQuery) await API.user.logout();
  };

export const registerUser = (data) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(toggleLoadingAC(true));
      let resp = await API.user.register(data);
      if (resp) {
        toast.dark('You are successfully registered!');
        toast.dark('Logging you in!');
        await API.user.getCsrf();
        let auth_resp = await API.user.auth({
          email: data.email,
          password: data.password,
        });
        if (auth_resp) {
          let me_resp = await API.user.getMe();
          if (me_resp?.company?.necs_account) {
            dispatch(setNecsAccount(cloneDeep(me_resp.company.necs_account)));
          } else {
            dispatch(setNecsAccount(null));
          }
          dispatch(setUserAC(me_resp));
        }
      }
      dispatch(toggleLoadingAC(false));
      resolve(true);
    } catch (error) {
      dispatch(toggleLoadingAC(false));
      onError({
        status: 0,
        message: 'Error on register new user!',
        deb_info: error,
      });
      reject(_.has(error, 'response.data.data.validation') ? error.response.data.data : error);
    }
  });
};

//  All Below Code For Old Chat
export const setUnreadChannels = (channels) => (dispatch) => {
  dispatch({ type: SET_UNREAD_CHANNELS, payload: channels });
};

export const addChannelToUnread = (channel) => (dispatch, getState) => {
  const {
    session: { unread_channels },
  } = getState();
  dispatch({
    type: SET_UNREAD_CHANNELS,
    payload: [...unread_channels, channel],
  });
};

export const removeChannelFromUnread = (channel) => (dispatch, getState) => {
  const {
    session: { unread_channels },
  } = getState();
  dispatch({
    type: SET_UNREAD_CHANNELS,
    payload: unread_channels.filter((c) => c !== channel),
  });
};

export const setChatUser = (user) => (dispatch) => {
  dispatch({ type: SET_CHAT_USER, payload: user });
};

export default sessionReducer;
