import { toast } from 'react-toastify';
import { useAppDispatch } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';
import {
  useLazyFetchNecsProductionDevicesQuery,
  useLazyGetProductionDeviceQuery,
  useUpdateProductionDeviceMutation,
  useLazySearchProductionDevicesQuery,
} from '../api/necs-production-device.api';

import { ProductionDeviceAC, setEditingProductionDevice } from '../store/necs-production-device.slice';
import {
  FetchProductionDevicesPayload,
  GetProductionDevicePayload,
  ProductionDeviceSearchResponse,
  SearchProductionDevicePayload,
  UpdateProductionDevicePayload,
} from '../types/necs-production-device.types';

interface UseNecsProductionDevice {
  getProductionDeviceHandler: (data: GetProductionDevicePayload) => Promise<boolean>;
  updateProductionDeviceHandler: (data: UpdateProductionDevicePayload) => Promise<boolean>;
  fetchProductionDevicesHandler: (data: FetchProductionDevicesPayload) => Promise<boolean>;
  searchProductionDevicesHandler: (payload: SearchProductionDevicePayload) => Promise<ProductionDeviceSearchResponse[]>;
}

export const useNecsProductionDevice = (): UseNecsProductionDevice => {
  const dispatch = useAppDispatch();

  const [getProductionDevice] = useLazyGetProductionDeviceQuery();
  const [updateProductionDevice] = useUpdateProductionDeviceMutation();
  const [fetchProductionDevices] = useLazyFetchNecsProductionDevicesQuery();
  const [searchProductionDevices] = useLazySearchProductionDevicesQuery();

  const getProductionDeviceHandler = async (payload: GetProductionDevicePayload) => {
    dispatch(ProductionDeviceAC.toggleLoading(true));
    const { data: response, error } = await getProductionDevice(payload);
    const isError = error || !response?.data?.id;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get NECS Production device!');
    } else {
      dispatch(setEditingProductionDevice(response.data));
    }

    dispatch(ProductionDeviceAC.toggleLoading(false));
    return !isError;
  };

  const updateProductionDeviceHandler = async (payload: UpdateProductionDevicePayload) => {
    dispatch(ProductionDeviceAC.toggleLoading(true));
    const { data: response, error } = await updateProductionDevice(payload);
    const isError = error || !response?.data?.id;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to save NECS Production device!');
    } else {
      toast.success(`Production Device was successfully saved!`);
    }

    dispatch(ProductionDeviceAC.toggleLoading(false));
    return !isError;
  };

  const fetchProductionDevicesHandler = async (payload: FetchProductionDevicesPayload) => {
    dispatch(ProductionDeviceAC.toggleLoading(true));
    const { data: response, error } = await fetchProductionDevices(payload);
    const isError = error || !response?.data;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get NECS Production devices!');
    } else {
      dispatch(ProductionDeviceAC.clearItems());
      dispatch(ProductionDeviceAC.setItems(response.data));
      dispatch(ProductionDeviceAC.setPaginationMeta(response.meta));
    }

    dispatch(ProductionDeviceAC.toggleLoading(false));
    return !isError;
  };

  const searchProductionDevicesHandler = async (payload: SearchProductionDevicePayload) => {
    const { data: response, error } = await searchProductionDevices(payload);
    if (response?.data) return response.data;
    return [];
  };

  return {
    getProductionDeviceHandler,
    updateProductionDeviceHandler,
    fetchProductionDevicesHandler,
    searchProductionDevicesHandler,
  };
};
