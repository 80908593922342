import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from 'modules/apiBaseQuery';
import { ApiPaginationResponse, ApiResponse } from 'types';
import {
  FetchGoCertificatePayload,
  IGoCertificate,
  UpdateGoCertificatePayload,
} from '../types/necs-go-certificate.type';

export const necsGoCertificateApi = createApi({
  reducerPath: 'necsGoCertificateApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}/v2/api/admin/necs/go-certificates`),
  endpoints: (builder) => ({
    fetchNecsGoCertificates: builder.query<ApiPaginationResponse<IGoCertificate[]>, FetchGoCertificatePayload>({
      query: (params) => ({
        url: '',
        method: 'GET',
        params,
      }),
    }),

    updateNecsGoCertificate: builder.mutation<ApiResponse<IGoCertificate>, UpdateGoCertificatePayload>({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const { useLazyFetchNecsGoCertificatesQuery, useUpdateNecsGoCertificateMutation } = necsGoCertificateApi;
