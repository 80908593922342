import { toast } from 'react-toastify';
import { useAppDispatch } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';
import { FetchGoCertificatePayload, UpdateGoCertificatePayload } from '../types/necs-go-certificate.type';
import { GoCertificateAC } from '../store/necs-go-certificate.slice';
import {
  useLazyFetchNecsGoCertificatesQuery,
  useUpdateNecsGoCertificateMutation,
} from '../api/necs-go-certificate.api';

interface UseNecsGoCertificate {
  fetchNecsGoCertificatesHandler: (payload: FetchGoCertificatePayload) => Promise<boolean>;
  setGoCertificateCommentHandler: (payload: UpdateGoCertificatePayload) => Promise<void>;
  saveNecsExchangesHandler: (payload: UpdateGoCertificatePayload) => Promise<boolean>;
}

export const useNecsGoCertificate = (): UseNecsGoCertificate => {
  const dispatch = useAppDispatch();
  const [fetchNecsGoCertificates] = useLazyFetchNecsGoCertificatesQuery();
  const [updateNecsGoCertificate] = useUpdateNecsGoCertificateMutation();

  const fetchNecsGoCertificatesHandler = async (payload: FetchGoCertificatePayload) => {
    dispatch(GoCertificateAC.toggleLoading(true));
    const { data: response, error } = await fetchNecsGoCertificates(payload);
    const isError = error || !response?.data;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get NECS Production devices!');
    } else {
      dispatch(GoCertificateAC.clearItems());
      dispatch(GoCertificateAC.setItems(response.data));
      dispatch(GoCertificateAC.setPaginationMeta(response.meta));
    }

    dispatch(GoCertificateAC.toggleLoading(false));
    return !isError;
  };

  const setGoCertificateCommentHandler = async (payload: UpdateGoCertificatePayload) => {
    dispatch(GoCertificateAC.toggleLoading(true));
    const { data: response, error } = await updateNecsGoCertificate(payload);
    dispatch(GoCertificateAC.toggleLoading(false));
    const isError = error || !response?.data;
    if (isError) {
      toast.error('Failed to change comment for Certificate Bundle!');
      return;
    }
    toast.success('Comment for Certificate Bundle successfully saved!');
  };

  const saveNecsExchangesHandler = async (payload: UpdateGoCertificatePayload) => {
    dispatch(GoCertificateAC.toggleLoading(true));
    const { data: response, error } = await updateNecsGoCertificate(payload);
    dispatch(GoCertificateAC.toggleLoading(false));
    const isError = error || !response?.data;
    if (isError) {
      toast.error('Failed to save exchanges of NECS GO certificate!');
      return false;
    }
    toast.success('Exchanges of NECS GO certificate successfully saved!');
    return true;
  };

  return {
    fetchNecsGoCertificatesHandler,
    setGoCertificateCommentHandler,
    saveNecsExchangesHandler,
  };
};
