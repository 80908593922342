import { toast } from 'react-toastify';

import { getApiErrorMessage } from 'utils';
import { useAppDispatch } from 'redux/store/store.types';
import { CompanyTypeAC } from '../store/company-type.slice';
import {
  useLazyFetchCompanyTypesQuery,
  useAddCompanyTypeMutation,
  useLazyDeleteCompanyTypeQuery,
  useUpdateCompanyTypeMutation,
} from '../api/company-type.api';
import {
  AddCompanyTypePayload,
  DeleteCompanyTypePayload,
  FetchCompanyTypePayload,
  UpdateCompanyTypePayload,
} from '../types/company-type.types';

interface UseCompanyType {
  fetchCompanyTypesHandler: (payload: FetchCompanyTypePayload) => Promise<boolean>;
  addCompanyTypeHandler: (payload: AddCompanyTypePayload) => Promise<boolean>;
  updateCompanyTypeHandler: (payload: UpdateCompanyTypePayload) => Promise<boolean>;
  deleteCompanyTypeHandler: (payload: DeleteCompanyTypePayload) => Promise<boolean>;
}

export const useCompanyType = (): UseCompanyType => {
  const dispatch = useAppDispatch();

  const [fetchCompanyTypes] = useLazyFetchCompanyTypesQuery();
  const [addCompanyType] = useAddCompanyTypeMutation();
  const [updateCompanyType] = useUpdateCompanyTypeMutation();
  const [deleteCompanyType] = useLazyDeleteCompanyTypeQuery();

  const fetchCompanyTypesHandler = async (payload: FetchCompanyTypePayload) => {
    dispatch(CompanyTypeAC.toggleLoading(true));
    const { data: response, error } = await fetchCompanyTypes(payload);
    const isError = error || !response?.data;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get Company Types!');
    } else {
      dispatch(CompanyTypeAC.clearItems());
      dispatch(CompanyTypeAC.setItems(response.data));
      dispatch(CompanyTypeAC.setPaginationMeta(response.meta));
    }

    dispatch(CompanyTypeAC.toggleLoading(false));
    return !isError;
  };

  const addCompanyTypeHandler = async (payload: AddCompanyTypePayload) => {
    const { data: response, error } = await addCompanyType(payload);
    const isError = error || !response?.data?.id;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to add Company Type!');
    }

    return !isError;
  };

  const updateCompanyTypeHandler = async (payload: UpdateCompanyTypePayload) => {
    const { error } = await updateCompanyType(payload);
    const isError = error;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to update Company Type!');
    }

    return !isError;
  };

  const deleteCompanyTypeHandler = async (payload: DeleteCompanyTypePayload) => {
    const { error } = await deleteCompanyType(payload);
    const isError = error;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to delete Company Type!');
    }

    return !isError;
  };

  return {
    fetchCompanyTypesHandler,
    addCompanyTypeHandler,
    updateCompanyTypeHandler,
    deleteCompanyTypeHandler,
  };
};
