import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { List, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import {
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
} from '@material-ui/icons';
import SideBar from './SideBar';
import NibMenu from '@components/common/NibMenu/NibMenu';
import AdminToExchangeButton from './AdminToExchangeButton';
import appLogo from '@assets/img/menu/reveel-logo.png';
import noavatar from '@assets/img/noavatar.svg';
import { _ } from '@helpers';
import { analyzeImage } from '@helpers';
import css from './appNav.module.sass';

const AppNav = ({ user, isSideBarOpen, setSideBar, logout, toggleHelp }) => {
  let history = useHistory();
  const { isHelpActive, platformSettings } = useSelector((state) => state.app);

  const [menuOpen, setMenu] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(appLogo);
  const [bgColor, setBgColor] = useState('black');

  const openProfile = () => {
    setMenu(!menuOpen);
  };

  const signout = async () => {
    try {
      await logout();
      history.push('/login');
    } catch (error) {
      console.log(error);
      toast.error('Error on logout');
    }
  };

  const getAvatarSrc = () => {
    return user && user.avatar ? `${process.env.REACT_APP_API_URL}${user.avatar.url}` : noavatar;
  };

  useEffect(() => {
    if (platformSettings?.logoUrl) {
      setCompanyLogo(platformSettings.logoUrl);
    }
  }, [platformSettings]);

  const rolesDisabled = !!Number(process.env.REACT_APP_DISABLE_ROLES);
  const userRolesText = !rolesDisabled && <>{user.roles.map((role) => _.capitalize(role.name)).join(', ')}</>;

  const handleMainSearchKeyDownEnter = (e) => {
    if (e.key === 'Enter') {
      alert('NO FUNCTION');
      console.log(this);
    }
  };

  if (!user) return <></>;

  return (
    <>
      <SideBar open={isSideBarOpen} user={user} getAvatarSrc={getAvatarSrc} userRolesText={userRolesText} />
      <header className={css.header + ' appHeader'}>
        <div
          className={css.SideBarBurger + ' ' + (isSideBarOpen ? css.SideBarBurgerOpen : '')}
          onClick={() => {
            setSideBar(!isSideBarOpen);
            setTimeout(function () {
              window.dispatchEvent(new Event('resize'));
            }, 500);
          }}
        >
          <div className={css.SideBarBurgerImage}></div>
        </div>
        <div className={css.HeaderLogo} onClick={() => history.push('/dashboard')}>
          <img src={companyLogo} alt="logo" />
        </div>
        <div className={css.headerSearchWrapper}>
          <AdminToExchangeButton />
          <div className={css.HeaderSearchBlock + ' HeaderSearchBlock'}>
            <div className={css.HeaderSearchInputBlock}>
              <div className={css.HeaderSearchIcon}></div>
              <input name="main_search" onKeyDown={handleMainSearchKeyDownEnter} placeholder="Search..." />
            </div>
            <div className={css.rightTools}>
              <IconButton onClick={toggleHelp}>
                <HelpOutlineOutlinedIcon className={isHelpActive ? css.active : ''} />
              </IconButton>
            </div>
            <div className={css.userProfile}>
              <div onClick={openProfile} className={css.userAvatar} style={{ backgroundColor: bgColor }}>
                <img
                  src={getAvatarSrc()}
                  alt="profile_logo"
                  crossOrigin="anonymous"
                  onLoad={(e) => {
                    const brightness = analyzeImage(e.target);
                    brightness !== null && setBgColor(brightness < 90 ? 'black' : '#D3D3D3');
                  }}
                />
                <NibMenu open={menuOpen}>
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem button onClick={() => history.push('/user-profile')}>
                      <ListItemIcon>
                        <PersonIcon style={{ color: '#fff' }} />
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </ListItem>
                    <ListItem button onClick={signout}>
                      <ListItemIcon>
                        <ExitToAppIcon style={{ color: '#fff' }} />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </NibMenu>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default AppNav;
