import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseDataReducerV2InitialState, basedDataReducerV2 } from 'redux/base-reducer-v2';
import { INecsTransaction } from '../types/necs-transaction.type';

export interface NecsTransactionInitialState extends BaseDataReducerV2InitialState<INecsTransaction> {
  perPage: number;
  selectedTransaction: INecsTransaction | null;
  transactionTypes: Array<{ id: string; name: string }>;
  transactionStatuses: Array<{ id: string; name: string }>;
}

const { initialState, AC, reducer, thunks } = basedDataReducerV2<INecsTransaction, NecsTransactionInitialState>(
  'necs-transaction',
);

export const necsTransactionInitialState: NecsTransactionInitialState = {
  ...initialState,
  perPage: 25,
  selectedTransaction: null,
  transactionTypes: [
    { id: 'buy', name: 'Buy' },
    { id: 'sell', name: 'Sell' },
  ],
  transactionStatuses: [
    { id: 'pending', name: 'Pending' },
    { id: 'completed', name: 'Completed' },
    { id: 'cancelled', name: 'Cancelled' },
  ],
};

export const necsTransaction = createSlice({
  name: 'necsTransaction',
  initialState: necsTransactionInitialState,
  reducers: {
    setSelectedNecsTransaction: (state, action: PayloadAction<INecsTransaction | null>) => {
      state.selectedTransaction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as NecsTransactionInitialState, action);
    });
  },
});

export const NecsTransactionAC = AC;
export const onNecsTransactionSort = thunks.onSort;
export const onNecsTransactionFilter = thunks.onFilter;
export const clearNecsTransactionFilters = AC.clearFilters;
export const setNecsTransactionCurrentPage = AC.setCurrentPage;

export const { setSelectedNecsTransaction } = necsTransaction.actions;
