export const KB_IN_MB = 1024;

const MAX_MB = 10;
export const MAX_FILE_SIZE = KB_IN_MB * MAX_MB;
export const FILE_SIZE_ERROR_MESSAGE = `File cannot be larger than ${MAX_MB}MB`;

export const TABLE_PAGE_SIZE = 10;

export const IOTA_MESSAGE_URL = `https://explorer.iota.org/${process.env.REACT_APP_BLOCKCHAIN_URL_PATH}/addr/`;
export const PUBLIC_PAGES = ['/registration', '/login', '/landings', '/404', '/password-reset'];
