import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseDataReducerV2InitialState, basedDataReducerV2 } from 'redux/base-reducer-v2';
import { IGoCertificate } from '../types/necs-go-certificate.type';

export interface NecsGoCertificateInitialState extends BaseDataReducerV2InitialState<IGoCertificate> {}

const { initialState, AC, reducer, thunks } = basedDataReducerV2<IGoCertificate, NecsGoCertificateInitialState>(
  'necs-go-certificate',
);

export const necsGoCertificateInitialState: NecsGoCertificateInitialState = {
  ...initialState,
  perPage: 25,
};

export const necsGoCertificate = createSlice({
  name: 'necsGoCertificate',
  initialState: necsGoCertificateInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as NecsGoCertificateInitialState, action);
    });
  },
});

export const GoCertificateAC = AC;
export const onGoCertificateSort = thunks.onSort;
export const onGoCertificateFilter = thunks.onFilter;
export const clearGoCertificateFilters = AC.clearFilters;
export const setGoCertificateCurrentPage = AC.setCurrentPage;

export const {} = necsGoCertificate.actions;
