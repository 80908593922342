import { fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

const removeNullParams = (params?: Record<string, any>): Record<string, any> | undefined => {
  if (!params) return params;
  return Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== null));
};

export const apiBaseQuery = (baseUrl: string): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> => {
  return async (args, api, extraOptions) => {
    if (typeof args === 'object' && 'params' in args) {
      args = { ...args, params: removeNullParams(args.params) };
    }

    const baseQuery = fetchBaseQuery({
      baseUrl,
      credentials: 'include',
      prepareHeaders: (headers) => {
        const xsrfToken = document.cookie
          .split('; ')
          .find((row) => row.startsWith('XSRF-TOKEN='))
          ?.split('=')[1];

        if (xsrfToken) {
          headers.set('X-XSRF-TOKEN', decodeURIComponent(xsrfToken));
        }
        headers.set('Accept', 'application/json');

        return headers;
      },
    });

    const result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
      window.location.href = '/login';
    }

    return result;
  };
};
