import { toast } from 'react-toastify';
import { useAppDispatch } from 'redux/store/store.types';
import { getApiErrorMessage } from 'utils';
import { FetchNecsTransactionPayload } from '../types/necs-transaction.type';
import { useLazyFetchNecsTransactionsQuery } from '../api/necs-transaction.api';
import { NecsTransactionAC, setSelectedNecsTransaction } from '../store/necs-transaction.slice';

interface UseNecsTransaction {
  fetchNecsTransactionsHandler: (payload: FetchNecsTransactionPayload) => Promise<boolean>;
  clearNecsTransactionsState: () => void;
}

export const useNecsTransaction = (): UseNecsTransaction => {
  const dispatch = useAppDispatch();
  const [fetchNecsTransactions] = useLazyFetchNecsTransactionsQuery();

  const fetchNecsTransactionsHandler = async (payload: FetchNecsTransactionPayload) => {
    dispatch(NecsTransactionAC.toggleLoading(true));
    const { data: response, error } = await fetchNecsTransactions(payload);
    const isError = error || !response?.data;
    if (isError) {
      toast.error(getApiErrorMessage(error) || 'Failed to get NECS Transactions!');
    } else {
      dispatch(NecsTransactionAC.clearItems());
      dispatch(NecsTransactionAC.setItems(response.data));
      dispatch(NecsTransactionAC.setPaginationMeta(response.meta));
    }

    dispatch(NecsTransactionAC.toggleLoading(false));
    return !isError;
  };

  const clearNecsTransactionsState = () => {
    dispatch(NecsTransactionAC.clearFilters());
    dispatch(NecsTransactionAC.clearSorts());
    dispatch(setSelectedNecsTransaction(null));
  };

  return {
    fetchNecsTransactionsHandler,
    clearNecsTransactionsState,
  };
};
