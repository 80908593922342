import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from 'modules/apiBaseQuery';
import { ApiPaginationResponse, ApiResponse } from 'types';
import { FetchNecsTransactionPayload, INecsTransaction } from '../types/necs-transaction.type';

export const necsTransactionApi = createApi({
  reducerPath: 'necsTransactionApi',
  baseQuery: apiBaseQuery(`${process.env.REACT_APP_API_URL}/v2/api/admin/necs/transactions`),
  endpoints: (builder) => ({
    fetchNecsTransactions: builder.query<ApiPaginationResponse<INecsTransaction[]>, FetchNecsTransactionPayload>({
      query: (params) => ({
        url: '',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useLazyFetchNecsTransactionsQuery } = necsTransactionApi;
